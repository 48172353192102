import React, { useState, useEffect } from "react"
import { darken } from "polished"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import NavLink from "shared/components/NavLink"
import Wrapper from "../Wrapper"
import { md } from "mill/utils/breakpoints"
import parameterize from "mill/utils/parameterize"

const NavigationContainer = styled.div`
  width: 100%;
  position: relative;

  ${props =>
    props && props.mode == "dark"
      ? `
    background: ${darken(0.03, "#2A4B55")};
    a {
      &:hover {
        background: ${darken(0.05, "#2A4B55")};
      }
    }
    ul {
      background: ${darken(0.03, "#2A4B55")};
    }
    span {
      color: rgba(255, 255, 255, 0.8);
    }
  `
      : `
    background: rgb(237, 237, 237);
    color: rgb(122, 131, 129);
    a {
    color: rgb(122, 131, 129);
      &:hover {
        background: rgb(229, 229, 229);
      }
      &.active {
        color: ${props.theme.colors.primary};
      }
    }
    ul {
      background: rgb(237, 237, 237);
    }
    span {
    color: rgb(122, 131, 129);
    }
  `}

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media ${md} {
    height: 5rem;

    nav {
      display: flex;
      justify-content: space-between;
    }

    ul {
      display: flex;
      justify-content: flex-start;
      height: 100%;
    }
  }
`

const NavItem = styled.li`
  @media ${md} {
    &:first-of-type: margin-left: 0;
  }
`

const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 4.8rem;

  color: ${props =>
    props.active ? props.theme.colors.primary : "rgba(255, 255, 255, 0.8)"};
  &:hover {
    cursor: pointer;
  }

  &.active {
    color: ${props => props.theme.colors.primary};
  }

  @media ${md} {
    line-height: 5rem;
    display: inline-block;
    height: 5rem;
    padding: 0 1.8rem;

    &.active {
      border-bottom: 2px solid ${props => props.theme.colors.primary};
    }
  }
`

const MobileContainer = styled.div`
  position: relative;

  ul {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);
    ${({ showMenu }) =>
      showMenu &&
      `
      max-height: 100vh;
      transition: max-height 0.3s ease-in-out;
    `}

    top: 5rem;
    left: -3rem;
    right: -3rem;
    z-index: 2;
    list-style: none;
    position: absolute;
    a {
      padding-left: 5.5rem;
      padding-right: 3rem;
    }
  }

  i {
    margin-right: 1.3rem;
    transition: transform 0.2s linear;
    ${({ showMenu }) => showMenu && `transform: scaleY(-1);`}
  }

  span {
    display: block;
    text-decoration: none;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 5rem;
  }

  a {
    display: block;
  }

  @media ${md} {
    display: none;
  }
`
const DesktopContainer = styled.div`
  display: none;

  @media ${md} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

// This is split out largely for simplification
const MobileItemNavigation = ({ items }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const location = useLocation()
  const selectedItem = items.find(
    i => i.path.split("?")[0] == location.pathname
  )
  const itemsToDisplay = items.filter(i => i != selectedItem)

  useEffect(() => {
    setShowMobileMenu(false)
  }, [location])

  return (
    <MobileContainer showMenu={showMobileMenu}>
      <span onClick={() => setShowMobileMenu(!showMobileMenu)}>
        <i className="fa fa-angle-down-regular"></i>
        {selectedItem?.title}
      </span>
      <ul>
        {itemsToDisplay.map(item => (
          <NavItem key={item.title} data-qa={parameterize(item.title)}>
            <StyledLink end to={item.path}>
              {item.title}
            </StyledLink>
          </NavItem>
        ))}
      </ul>
    </MobileContainer>
  )
}

const DesktopItemNavigation = ({ items, otherItems }) => {
  return (
    <DesktopContainer>
      <ul>
        {items.map(item => (
          <NavItem key={item.title} data-qa={parameterize(item.title)}>
            <StyledLink end to={item.path}>
              {item.title}
            </StyledLink>
          </NavItem>
        ))}
      </ul>

      {otherItems && (
        <ul className="other-items">
          {otherItems.map(item => (
            <NavItem key={item.title} data-qa={parameterize(item.title)}>
              <StyledLink end to={item.path}>
                {item.title}
              </StyledLink>
            </NavItem>
          ))}
        </ul>
      )}
    </DesktopContainer>
  )
}

const AdminSubNavigation = ({ items, otherItems, mode }) => {
  const allItems = otherItems ? [...items, ...otherItems] : items
  return (
    <NavigationContainer mode={mode}>
      <Wrapper>
        <nav>
          <MobileItemNavigation items={allItems} />
          <DesktopItemNavigation items={items} otherItems={otherItems} />
        </nav>
      </Wrapper>
    </NavigationContainer>
  )
}

export default AdminSubNavigation

AdminSubNavigation.defaultProps = {
  items: [],
  mode: "dark"
}
